import React, { Component } from 'react'
import { Form, Button, Radio, message, Modal, Result, Checkbox } from 'antd';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import './index.scss'
import {
    getInterviewTime, uploadImg, uploadInterviewImg, checkEnterStatus,
    getPaper, addCheatsTimes, submitChoice, addCheats, examFinish
} from '../utils/api'

let video
let interId
let takePh

class choice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
            laTexData: [],
            quesData: [],
            addData: [],
            visible: false,
            time: (new Date()).toLocaleString(),
            during: 0,
            id: null,
            showResult: false,
            cheat: -1,
            showConfirm: false,
            round: null
        }
    }

    componentDidMount = () => {
        const cur = this
        video = document.getElementById("video")
        if (process.env.NODE_ENV == 'production') {
            this.getMedia()
        }
        let str = this.props.location.search.split('=')
        let id = str[1].split('&')[0]
        let round = str[2]
        this.setState({
            id,
            round
        })
        checkEnterStatus({
            id,
            round
        }).then(res => {
            if (res.code == 1) {
                this.props.history.push('/login')
                message.error(res.msg)
            }
        })
        getPaper({
            id,
            round
        }).then(res => {

            
            let da = res.data


            if (da[0].post == 1) {
                for (let i = 0; i < da.length; i++) {
                    let ar = this.dealLaTex(da[i].stem)
                    let a = da[i].option_list.split('@@')
                    if (da[i].option_a) {
                        da[i].arr_a = this.dealLaTex(a[0])
                        da[i].arr_b = this.dealLaTex(a[1])
                        da[i].arr_c = this.dealLaTex(a[2])
                        da[i].arr_d = this.dealLaTex(a[3])
                    }
                    da[i].arr = ar
                }
                cur.setState({
                    laTexData: da,
                    post: 1
                })
            } else if (da[0].post == 2) {
                cur.setState({
                    quesData: da,
                    post: 2
                })
            }
        })

        if (process.env.NODE_ENV == 'production') {
            window.addEventListener('resize', this.onResize)
            window.onblur = function () {
                addCheatsTimes({ id })
                cur.setState({
                    visible: true,
                    cheat: cur.state.cheat + 1
                })
            }
        }
        let times
        getInterviewTime({ id }).then(res => {
            times = (round == 1 ? new Date(res.data.start_time).getTime() : new Date(res.data.tech_time).getTime())
        })
        interId = setInterval(() => {
            this.setState({
                time: (new Date()).toLocaleString(),
            })
            this.countTime(times)
        }, 1000)
        takePh = setInterval(() => {
            let canvas = document.getElementById("canvas")
            let ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, 500, 300)
            var imgData = canvas.toDataURL()
            uploadImg({
                imgData: imgData,
                name: 'name_img',
            }).then(res => {
                uploadInterviewImg({
                    img: res.data,
                    id: this.state.id
                })
            })
        }, 1000 * 60 * 5)
    }

    componentWillUnmount() {
        clearInterval(interId)
        clearInterval(takePh)
    }

    onResize = () => {
        if (this.state.cheat == -1) {
            this.setState({
                cheat: 0
            })
        } else {
            if (this.state.visible == false) {
                addCheatsTimes({ id: this.state.id })
                this.setState({
                    visible: true,
                    cheat: this.state.cheat + 1
                })
            }
        }
    }

    countTime(time) {
        let getNowTime = new Date()
        let t = getNowTime.getTime() - time
        let hour = Math.floor((t / 3600000) % 24)
        let min = Math.floor((t / 60000) % 60)
        let sec = Math.floor((t / 1000) % 60)
        if (hour) {
            this.setState({
                during: hour + '时' + min + '分' + sec + '秒'
            })
        } else {
            this.setState({
                during: min + '分' + sec + '秒'
            })
        }
    }

    dealLaTex = (str) => {
        let s2 = str.split('$$')
        for (let i = 0; i < s2.length; i++) {
            if (i % 2 === 0) {
                let ss = s2[i].split('$')
                for (let j = 0; j < ss.length; j++) {
                    if (j % 2 === 0) {
                    } else {
                        ss[j] = '@@' + ss[j]
                    }
                }
                s2[i] = ss
            } else {
                s2[i] = '@@@' + s2[i]
            }
        }
        let s = s2.flat().filter(function (s) { return s && s.trim() })
        return s
    }
    onChangeRadio = (e, index) => {
        let a = this.state.addData
        a[index] = e.target.value
        this.setState({
            addData: a
        })
    }

    onChangeChecked = (e, index) => {
        let a = this.state.addData
        a[index] = e.join(',')
        this.setState({
            addData: a
        })
    }

    submit = () => {
        this.setState({
            showConfirm: true,
        });
    }
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };
    confirmOk = () => {
        let d = this.state.addData
        let flag = true
        for (let i = 0; i < d.length; i++) {
            if (!d[i]) {
                flag = false
            }
        }
        // todo 暂时把个数限制去了
        if (d.length === 15 && flag) {
            submitChoice({
                answer: this.state.post == 1 ? this.state.addData.join(',') : this.state.addData.join('-'),
                id: this.state.id
            }).then(res => {
                if (res.code == 1) {
                    message.error(res.msg)
                    this.setState({
                        showConfirm: false,
                        showResult: true
                    })
                } else {
                    this.props.history.push('/exam?id=' + this.state.id + '&round=' + this.state.round)
                }
            })
            // 提交切屏次数
            addCheats({
                times: this.state.cheat == -1 ? 0 : this.state.cheat,
                id: this.state.id
            })
            examFinish({
                time: this.state.during,
                id: this.state.id,
                type: 1
            })

        } else {
            this.setState({
                showConfirm: false
            })
            message.error('请检查是否全部题目都已作答！');
        }
    }
    confirmCancel = () => {
        this.setState({
            showConfirm: false,
        });
    }
    getMedia() {
        let constraints = {
            video: { width: 500, height: 300 },
            audio: true
        }
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise.then(function (MediaStream) {
            video.srcObject = MediaStream;
            video.play();
        }).catch(function (PermissionDeniedError) {
            console.log(PermissionDeniedError);
        })
        this.setState({
            showVideo: true
        })
    }


    render() {
        const { laTexData, showResult, post, quesData } = this.state
        const radioStyle = {
            display: 'flex',
            width: '50%',
            height: '30px',
            lineHeight: '30px',
        }
        return (
            <div className='contanier'>
                {/* part A */}
                {showResult ? <Result
                    title="很抱歉，你未能通过选择题部分的考试，感谢您的参与。"
                /> : <Form layout="vertical" hideRequiredMark>
                    <div className='part-title'>part A {post == 1 ? '选择题' : '不定项选择'} </div>

                    <video id="video" className='vidieos' width="500px" height="300px" autoPlay="autoplay"></video>
                    <canvas id="canvas" className='canvas' width="500px" height="300px"></canvas>
                    {/* 考试计时 */}
                    <div className='time'>{this.state.time}</div>
                    <div className='time'>考试已经开始：{this.state.during} </div>

                    {post == 1 ? <>
                        {laTexData.map((data, index) => {
                            return (
                                <div>
                                    {/* 题目 */}
                                    <div>
                                        <span>{index + 1}  </span>
                                        {data.arr && data.arr.map((item, index) => {
                                            if (item.indexOf('@@@') > -1) {
                                                return <BlockMath key={index}>{String.raw`${item.split("@@@")[1]}`}</BlockMath>
                                            } else if (item.indexOf('@@') > -1) {
                                                return <InlineMath key={index}>{String.raw`${item.split("@@")[1]}`}</InlineMath>
                                            } else {
                                                return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                            }
                                        })}
                                    </div>
                                    <div className='options'>
                                        <Radio.Group onChange={(e) => this.onChangeRadio(e, index)} >
                                            <Radio style={radioStyle} value={1}>
                                                <span>A: </span>
                                                {data.arr_a && data.arr_a.map((item, index) => {
                                                    if (item.indexOf('@@@') > -1) {
                                                        return <BlockMath key={index}>{String.raw`${item.split("@@@")[1]}`}</BlockMath>
                                                    } else if (item.indexOf('@@') > -1) {
                                                        return <InlineMath key={index}>{String.raw`${item.split("@@")[1]}`}</InlineMath>
                                                    } else {
                                                        return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                                    }
                                                })}</Radio>
                                            <Radio style={radioStyle} value={2}>
                                                <span>B: </span>
                                                {data.arr_b && data.arr_b.map((item, index) => {
                                                    if (item.indexOf('@@@') > -1) {
                                                        return <BlockMath key={index}>{String.raw`${item.split("@@@")[1]}`}</BlockMath>
                                                    } else if (item.indexOf('@@') > -1) {
                                                        return <InlineMath key={index}>{String.raw`${item.split("@@")[1]}`}</InlineMath>
                                                    } else {
                                                        return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                                    }
                                                })}</Radio>
                                            <Radio style={radioStyle} value={3}>
                                                <span>C: </span>
                                                {data.arr_c && data.arr_c.map((item, index) => {
                                                    if (item.indexOf('@@@') > -1) {
                                                        return <BlockMath key={index}>{String.raw`${item.split("@@@")[1]}`}</BlockMath>
                                                    } else if (item.indexOf('@@') > -1) {
                                                        return <InlineMath key={index}>{String.raw`${item.split("@@")[1]}`}</InlineMath>
                                                    } else {
                                                        return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                                    }
                                                })}</Radio>
                                            <Radio style={radioStyle} value={4}>
                                                <span>D: </span>
                                                {data.arr_d && data.arr_d.map((item, index) => {
                                                    if (item.indexOf('@@@') > -1) {
                                                        return <BlockMath key={index}>{String.raw`${item.split("@@@")[1]}`}</BlockMath>
                                                    } else if (item.indexOf('@@') > -1) {
                                                        return <InlineMath key={index}>{String.raw`${item.split("@@")[1]}`}</InlineMath>
                                                    } else {
                                                        return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                                    }
                                                })}</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            )
                        })}


                    </> : <>

                        {quesData.map((item, index) => {
                            return (
                                <div className='ques'>
                                    {/* <span>{index + 1}  </span> */}
                                    <div>{index + 1} {item.stem}</div>
                                    <Checkbox.Group onChange={(e) => this.onChangeChecked(e, index)}>
                                        <Checkbox value={1}>A. {item.option_a}</Checkbox>  <br />
                                        <Checkbox value={2}>B. {item.option_b}</Checkbox>  <br />
                                        <Checkbox value={3}>C. {item.option_c}</Checkbox>  <br />
                                        <Checkbox value={4}>D. {item.option_d}</Checkbox>
                                    </Checkbox.Group>

                                </div>
                            )
                        })}

                    </>}

                    <Button type="primary" style={{ marginBottom: 14 }} onClick={this.submit.bind(this)} >提交答案</Button>
                </Form>
                }

                <Modal
                    maskStyle={{ background: '#000' }}
                    title="禁止拖动屏幕"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p> 请在答题界面安心作答！</p>
                </Modal>

                <Modal
                    title="选择题提交！"
                    visible={this.state.showConfirm}
                    okText='确认'
                    cancelText='返回继续作答'
                    onOk={this.confirmOk}
                    onCancel={this.confirmCancel}
                >
                    <p>选择题部分只能提交一次，提交之后不可回退或再次修改答案！</p>
                </Modal>
            </div>

        );
    }
}

export default choice;