import React, { Component } from 'react'
import { Upload, Modal, Button, Image, message, Result } from 'antd';
import 'katex/dist/katex.min.css';
import './index.scss'
import { SmileOutlined, UploadOutlined } from '@ant-design/icons';
import {
    getInterviewTime, uploadImg, uploadInterviewImg, uploadAnswer
} from '../utils/api'
let video
let interId
let takePh

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class Uploads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
            imgList: [],
            showFinish: false,
            time: (new Date()).toLocaleString(),
            startTime: null,
            unFinish: false,
            round: null
        }
    }

    componentDidMount() {
        video = document.getElementById("video")
        if (process.env.NODE_ENV == 'production') {
            this.getMedia()
        }
        let str = this.props.location.search.split('=')
        let id = str[1].split('&')[0]
        let time = str[2]
        console.log('timeee', time)

        this.setState({
            id,
            startTime: time
        })
        interId = setInterval(() => {
            this.setState({
                time: (new Date()).toLocaleString(),
            })
            this.countTime(time)
        }, 1000)
        takePh = setInterval(() => {
            let canvas = document.getElementById("canvas");
            let ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, 500, 300);
            var imgData = canvas.toDataURL();
            uploadImg({
                imgData: imgData,
                name: 'name_img',
            }).then(res => {
                uploadInterviewImg({
                    img: res.data,
                    id: this.state.id
                })
            })
        }, 1000 * 60 * 4)
        getInterviewTime({ id }).then(res => {
            this.setState({
                round: res.data.round
            })
        })

    }

    componentWillUnmount() {
        clearInterval(interId)
        clearInterval(takePh)
    }

    countTime(time) {
        let getNowTime = new Date()
        let t = getNowTime.getTime() - time
        let hour = Math.floor((t / 3600000) % 24)
        let min = Math.floor((t / 60000) % 60)
        let sec = Math.floor((t / 1000) % 60)
        if (min > 10) {
            // this.setState({
            //     showFinish: true,
            //     unFinish: true
            // })
            this.props.history.push('/login')
            message.error('提交答卷超时！')
        }
        if (hour) {
            this.setState({
                during: hour + '时' + min + '分' + sec + '秒'
            })
        } else {
            this.setState({
                during: min + '分' + sec + '秒'
            })
        }
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    handleChange = ({ fileList }) => {
        this.setState({ fileList })
    }

    finishSubmit = () => {
        this.setState({
            showConfirm: true
        })
    }

    handleOk = e => {
        this.setState({
            showConfirm: false
        });
    }

    handleCancel = e => {
        this.setState({
            showConfirm: false,
            previewVisible: false,
        });
    };
    confirmOk = () => {
        const cur = this
        if (!this.state.imgList[0]) {
            message.error('上传失败，没有待上传答卷')
            return
        }
        let exam_answer = this.state.imgList.join('@@')
        uploadAnswer({
            exam_answer,
            id: this.state.id,
            round: this.state.round
        }).then(res => {
            if (res.code == 0) {
                message.success("上传成功")
                cur.setState({
                    showFinish: true
                })
            } else {
                message.error('上传失败，你已经提交过答卷')
            }
        })
    }

    getMedia() {
        let constraints = {
            video: { width: 500, height: 300 },
            audio: true
        }
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise.then(function (MediaStream) {
            video.srcObject = MediaStream;
            video.play();
        }).catch(function (PermissionDeniedError) {
            console.log(PermissionDeniedError);
        })
        this.setState({
            showVideo: true
        })
    }

    handleChange2 = info => {
        const cur = this
        if (info.file.response) {
            cur.setState({
                imgList: cur.state.imgList.concat(info.file.response.data.filename)
            })
        }
    }

    render() {
        const { previewVisible, previewImage, fileList, previewTitle, imgList, showFinish, unFinish } = this.state;
        const props = {
            action: process.env.NODE_ENV == 'development' ?
                'http://10.1.1.41:7001/dev/exam/api/uploadTestPaper' :
                'https://www.higgsasset.com/higgs/exam/api/uploadTestPaper',
            onChange: this.handleChange2,
            multiple: true
        }

        return (
            <>
                {showFinish ?
                    <>
                        {unFinish ? <Result
                            // icon={<SmileOutlined />}
                            status="warning"
                            title="试卷上传超时，请将答卷发送至 hr@higgsasset.com，笔试结果预计需要一周时间，请耐心等待，祝您生活愉快！"
                        /> : <Result
                            icon={<SmileOutlined />}
                            title="试卷已提交成功，感谢您的参与，若有问题请联系hr@higgsasset.com，笔试结果预计需要一周时间，请耐心等待，祝您生活愉快！"
                        />}
                    </>
                    : <div className='upload_contanier'>
                        <div className='title'>请上传答卷</div>
                        <div className='title'>您有<span>10</span>分钟完成答卷上传</div>
                        <div className='title'>支持.jpg .jpeg .png图片格式，其他格式暂不支持</div>
                        <div className='title'>若发现上传图片，未生成预览图，则尝试将图片截图或者转换图片格式重新上传</div>
                        <div className='title'>若传错或者失败图片过多，则可以刷新当前页，重新进行上传</div>

                        <video id="video" className='vidieos' width="500px" height="300px" autoPlay="autoplay"></video>
                        <canvas id="canvas" className='canvas' width="500px" height="300px"></canvas>
                        {/* <div className='time'>{this.state.time}</div> */}
                        <div className='time'>交卷已经开始：{this.state.during} </div>
                        <Upload
                            name="avatar"
                            {...props}
                            fileList={this.state.fileList}
                        >
                            <Button className='finishUp' icon={<UploadOutlined />}>点击上传答卷</Button>
                        </Upload>
                        <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                        <div>
                            {imgList.map((item, index) => {
                                return (
                                    <Image
                                        width={200}
                                        src={item}
                                    />
                                )
                            })}
                        </div>
                        <Button type="primary" onClick={this.finishSubmit.bind(this)} >完成上传</Button>
                        <Modal
                            title="确认提交答案"
                            visible={this.state.showConfirm}
                            okText='确认'
                            cancelText='检查答卷'
                            onOk={this.confirmOk}
                            onCancel={this.handleCancel}
                        >
                            <p>答卷只有一次提交机会，请确认上传内容、数量无误！</p>
                        </Modal>
                    </div>
                }
            </>
        );
    }
}

export default Uploads;